.booked_day>.rdp-day_button {
    position: relative;
    /* Ensure that the dots are positioned relative to the button */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.booked_day>.rdp-day_button::after {
    content: '';
    width: 6px;
    height: 6px;
    background-color: #86D511;
    border-radius: 50%;
    position: absolute;
    bottom: 4px;
    /* Adjust positioning as needed */
    left: 50%;
    transform: translateX(-50%);
}

.cannot_be_booked_day>.rdp-day_button {
    position: relative;
    /* Ensure that the dots are positioned relative to the button */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cannot_be_booked_day>.rdp-day_button::after {
    content: '';
    width: 6px;
    height: 6px;
    background-color: #DF535F;
    border-radius: 50%;
    position: absolute;
    bottom: 4px;
    /* Adjust positioning as needed */
    left: 50%;
    transform: translateX(-50%);
}