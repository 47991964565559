@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.clip-bottom {
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
}


/* css loader generated using https://10015.io/tools/css-loader-generator */
.spinner {
  margin: 40px;
  width: 11.2px;
  height: 11.2px;
  animation: spinner-z355kx 1s infinite linear;
  border-radius: 11.2px;
  box-shadow: 28px 0px 0 0 #6EC9E0, 17.4px 21.8px 0 0 #6EC9E0, -6.2px 27.2px 0 0 #6EC9E0,
    -25.2px 12px 0 0 #6EC9E0, -25.2px -12px 0 0 #6EC9E0, -6.2px -27.2px 0 0 #6EC9E0,
    17.4px -21.8px 0 0 #6EC9E0;
}

@keyframes spinner-z355kx {
  to {
    transform: rotate(360deg);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
