.calendar-container {
    width: 100%;
    max-width: 1200px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.rbc-calendar {
    border: none;
}

.rbc-time-view {
    border: 0 !important;
}

.rbc-header {
    min-height: 3rem !important;
    border: 0 !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    white-space: pre-line !important;
    align-items: center !important;
    margin-top: 0 !important;
}

.rbc-header+.rbc-header {
    border-left: 1px solid #ddd;
}

.rbc-time-header {
    /* border: 1px solid #D4D4D4 !important; */
    border-radius: 8px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    margin-right: 0 !important;
    background-color: #FFFFFF !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.rbc-time-content {
    display: flex;
    flex: 1 0;
    align-items: flex-start;
    width: 100%;
    border-radius: 8px !important;
    border: 1px solid #D4D4D4 !important;
    background-color: #FFFFFF !important;
    overflow-y: auto !important;
    scrollbar-width: thin !important;
    scrollbar-color: #3CA5DC #E5F2FF !important;
    scroll-behavior: smooth !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.custom-time-content1 {
    display: flex;
    flex: 1 0;
    align-items: flex-start;
    width: 100%;
    overflow-y: auto !important;
    max-height: 100% !important;
    border-radius: 8px !important;
    border: 1px solid #D4D4D4 !important;
    background-color: #FFFFFF !important;
    scrollbar-width: thin !important;
    scrollbar-color: #3CA5DC #E5F2FF !important;
    scroll-behavior: smooth !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

/* WebKit browsers (Chrome, Safari) */
/* .rbc-time-content::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
}

.rbc-time-content::-webkit-scrollbar-track {
    background: #E5F2FF !important;
    border-radius: 10px !important;
}

.rbc-time-content::-webkit-scrollbar-thumb {
    background-color: #3CA5DC !important;
    border-radius: 10px !important;
    border: 2px solid #E5F2FF !important;
} */

.rbc-time-header-content {
    background-color: #FFFFFF;
    border: 0 !important;
    border-radius: 4px !important;
}

.rbc-allday-cell {
    border: 0 !important;
    height: 0 !important;
}

.rbc-row-content {
    width: 0 !important;
    height: 0 !important;
}

.rbc-time-header-cell {
    border: 0 !important;
}

.rbc-button-link {
    color: #545454;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #eee;
}

.rbc-day-bg+.rbc-day-bg {
    border-left: 1px solid #ddd;
}

.rbc-event {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 4px;
    background-color: #F1F9FD !important;
    border: 1px solid #3CA5DC !important;
}

.rbc-upcoming-events {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 4px;
    background-color: #F1F9FD !important;
    border: 1px solid #3CA5DC !important;
}

.rbc-past-events {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 4px;
    background-color: #F2F3F4 !important;
    border: 1px solid #D4D4D4 !important;
}

.rbc-event-label {
    color: #1C1C1C;
    font-size: 12px !important;
    font-family: Arial, sans-serif;
    font-weight: 400;
}

.custom-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    /* border: 1px solid #D4D4D4 !important; */
}

.custom-toolbar button {
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
}

.custom-toolbar-label {
    font-size: 14px;
    font-weight: 600;
}

.custom-time-slot {
    border-top: 1px solid #ddd;
}

.rbc-time-content {
    border-top: 0 !important;
    border-right: 1px solid #D4D4D4;
}

.rbc-timeslot-group {
    min-height: 4.5rem !important;
}

.rbc-events-container {
    /* margin-right: 0 !important; */
}

.rbc-day-bg {
    border-right: 1px solid #ddd;
}

.rbc-time-slot {
    border-top: 1px solid #ddd;
}

.rbc-label {
    font-size: 12px;
    font-family: Arial, sans-serif;
    font-weight: 550;
}
/* 
.rbc-current-time-indicator {
    background-color: #FC2424 !important;
    height: 2px !important;
    position: relative;
}

.rbc-current-time-indicator::after {
    content: '';
    position: absolute;
    left: -9px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: #FC2424;
    border-radius: 50%;
    z-index: 90 !important;
} */

/* .custom-toolbar>button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E5F2FF !important;
    height: 2rem;
    width: 2rem;
    padding-bottom: 8px;
    border-radius: 50%;
    color: #3CA5DC !important;
    border: none;
    font-size: 30px;
    cursor: pointer;
} */