.ResizeHandleOuter {
  flex: 0 0 1.5em;
  position: relative;
  outline: none;

  --background-color: transparent;
}

.ResizeHandleOuter[data-resize-handle-active] {
  --background-color: var(--color-solid-resize-bar-handle);
}

.ResizeHandleInner {
  position: absolute;
  top: 0.25em;
  bottom: 0.25em;
  left: 0.25em;
  right: 0.25em;
  border-radius: 0.25em;
  background-color: var(--background-color);
  transition: background-color 0.2s linear;
}

.Icon1 {
  width: 1.2em;
  height: 1.5em;
  color: #3CA5DC;
  background-color: #E5F2FF;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 0.6rem);
  top: calc(50% - 0.5rem);
  transform: rotate(90deg);
}

.Icon2 {
  width: 2em;
  height: 1.2em;
  color: #3CA5DC;
  background-color: #E5F2FF;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 0.5rem);
  top: calc(50% - 0.5rem);
}
