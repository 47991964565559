.radio-label {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.radio-input {
  margin: 0;
  visibility: hidden;
}

.radio-input:checked + span {
  border: 2px solid #3CA5DC;
  background-color: #3CA5DC;
}

.radio-input:checked + span:after {
  opacity: 1;
}

.custom-radio {
  left: -8px;
  top: 1px;
  cursor: pointer;
  width: 17px;
  height: 17px;
  border: 2px solid #9a9a9a;
  border-radius: 2px; /* box shape */
  display: inline-block;
  position: relative;
  background-color: white;
}

/* for inner tick mark */
.custom-radio::after {
  content: '';
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  opacity: 0;
  transition: opacity 0.2s;
}
